
















































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";
@Component({})
export default class EditOffice extends Vue {
  private form: Record<string, unknown> = {};
  private errors: Record<string, Array<string>> = {};

  mounted() {
    if (this.$route.params.id) {
      this.office(Number(this.$route.params.id));
    }
  }
  public async office(id: number): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().showNotification(id);
      this.form = {
        ...res.data.data
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      const res: AxiosResponse = await new GeneralApi().storeNotification(
        form,
        Number(this.$route.params.id)
      );
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
